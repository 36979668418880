import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Observer, of, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { Constant } from '@app/shared/types/types';

@Injectable({
    providedIn: 'root'
})
export class ConstantsService {
    private constants: any;
    private retrieveConstantsInProgress = false;
    private retrieveConstantsSource = new Subject();
    private retrieveConstants$ = this.retrieveConstantsSource.asObservable();

    constructor(
        private httpClient: HttpClient
    ) { }

    getConstants(): Observable<any> {
        if (this.constants) {
            return of(this.constants);
        } else {
            return this.handleMultipleAPIRequest();
        }
    }

    retrieveConstants(): Observable<Record<Constant, unknown>> {
        const url = 'get_constants';

        return this.httpClient.get<Record<Constant, unknown>>(url).pipe(
            tap((response: Record<Constant, unknown>) => {
                this.constants = response;
            })
        );
    }

    handleMultipleAPIRequest(): Observable<Record<Constant, unknown>> {
        if (this.retrieveConstantsInProgress) {
            return new Observable((observer: Observer<Record<Constant, unknown>>) => {
                this.retrieveConstants$.pipe(
                    finalize(() => this.retrieveConstantsInProgress = false)
                ).subscribe((constants: any) => {
                    observer.next(constants);
                    observer.complete();
                });
            });
        } else {
            this.retrieveConstantsInProgress = true;
            return this.retrieveConstants().pipe(
                tap((constants: any) => {
                    this.retrieveConstantsSource.next(constants);
                }),
                finalize(() => this.retrieveConstantsInProgress = false)
            );
        }
    }
}
