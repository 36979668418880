import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ThemeService } from '@app/core/services/theme.service';

@Component({
    selector: 'rc-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ColorPickerComponent),
        multi: true
    }]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
    public color!: string;
    public hexColor!: string;
    public isDisabled!: boolean;
    public onChange = (data: string) => { };
    public onTouched = () => { };

    @Input() label = '';
    @Input() type: 'primary' | 'accent' = 'primary';
    @Input() set readonly(value: string) {
        this.isDisabled = coerceBooleanProperty(value);
    }

    constructor(
        private themeService: ThemeService
    ) { }

    ngOnInit(): void {
    }

    onColorPickerSelected(hslColor: string): void {
        this.writeValue(hslColor);
        this.onChange(hslColor);
    }

    //
    // ─── CONTROL VALUE ACCESSOR METHODS ─────────────────────────────────────────────
    //

    writeValue(data: string): void {
        this.color = data;
        if (data) {
            this.hexColor = data.includes('hsl')
                ? this.themeService.getHexColor(data)
                : data.trim();
        }
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

}
