export class Constants {
    public static readonly TOKEN_NAME = 'TOKEN';
    public static readonly REFRESH_TOKEN_NAME = 'REFRESH_TOKEN';
    public static readonly USER_DATA = 'USER_DATA';
    public static readonly SELECTED_CUSTOMER = 'SELECTED_CUSTOMER';

    public static readonly DEFAULT_PRIMARY_COLOR = '#004a75';
    public static readonly DEFAULT_PRIMARY_COLOR_CONTRAST = '#202122';
    public static readonly DEFAULT_ACCENT_COLOR = '#fbbb48';
    public static readonly DEFAULT_ACCENT_COLOR_CONTRAST = '#202122';

    public static readonly DEFAULT_SNACKBAR_DURATION = 5000;

    public static readonly EXPENSE_REPORTS_LIST_SORTING_TYPES = 'EXPENSE_REPORTS_LIST_SORTING_TYPES';
    public static readonly EXTERNAL_CODE = 'EXTERNAL_CODE';
    public static readonly DATE = 'DATE';
    public static readonly DESCRIPTION = 'DESCRIPTION';
    public static readonly EMPLOYEE_EXTERNAL_CODE = 'EMPLOYEE_EXTERNAL_CODE';
    public static readonly EMPLOYEE_NAME = 'EMPLOYEE_NAME';
    public static readonly COMPANY_NAME = 'COMPANY_NAME';
    public static readonly COMPANY_EXTERNAL_CODE = 'COMPANY_EXTERNAL_CODE';
    public static readonly IMPORT_DATE_CODE = 'IMPORT_DATE_CODE';
    public static readonly REPORT_KEY_CODE = 'REPORT_KEY_CODE';
    public static readonly ID_CODE = 'ID_CODE';

    public static readonly EXPENSE_REPORT_LINES_LIST_SORTING_TYPES = 'EXPENSE_REPORT_LINES_LIST_SORTING_TYPES';
    public static readonly EXPENSE_DATE = 'EXPENSE_DATE';
    public static readonly EXPENSE_TYPE = 'EXPENSE_TYPE';
    public static readonly CITY = 'CITY';
    public static readonly PAYMENT_TYPE = 'PAYMENT_TYPE';
    public static readonly AMOUNT = 'AMOUNT';
    public static readonly RECEIPT_STATUS = 'RECEIPT_STATUS';

    public static readonly EXPENSE_REPORT_SOURCES = 'EXPENSE_REPORT_SOURCES';
    public static readonly SORTING_ORDERS = 'SORTING_ORDERS';

    public static readonly USER_ROLES = 'USER_ROLES';
    public static readonly SUPER_ADMIN = 'SUPER_ADMIN';
    public static readonly CUSTOMER_ADMIN = 'CUSTOMER_ADMIN';
    public static readonly COMPANY_ADMIN = 'COMPANY_ADMIN';
    public static readonly ADMIN = 'ADMIN';
    public static readonly STANDARD_USER = 'STANDARD_USER';

    public static readonly EXPENSE_REPORT_APPLICATION_STATUSES = 'EXPENSE_REPORT_APPLICATION_STATUSES';
    public static readonly CONSERVA_AVAILABLE = 'CONSERVA_AVAILABLE';
    public static readonly CONSERVA_ARCHIVED = 'CONSERVA_ARCHIVED';
    public static readonly REVOICE_AVAILABLE = 'REVOICE_AVAILABLE';
    public static readonly REVOICE_ARCHIVED = 'REVOICE_ARCHIVED';
    public static readonly REVOICE_MERGED = 'REVOICE_MERGED';

    public static readonly CUSTOMER_SETTING_TYPES = 'CUSTOMER_SETTING_TYPES';
    public static readonly LOGO = 'LOGO';
    public static readonly MAIN_COLOR = 'MAIN_COLOR';
    public static readonly SECONDARY_COLOR = 'SECONDARY_COLOR';

    public static readonly USER_LANGUAGES = 'USER_LANGUAGES';
    public static readonly LANGUAGE_IT = 'IT';
    public static readonly LANGUAGE_EN = 'EN';

    public static readonly CUSTOMER_CONFIGURATION = 'CUSTOMER_CONFIGURATION';
    public static readonly CONCUR_USERNAME = 'api.params.username';
    public static readonly CONCUR_PASSWORD = 'api.params.password';
    public static readonly CONCUR_CLIENT_ID = 'api.params.client_id';
    public static readonly CONCUR_CLIENT_SECRET = 'api.params.client_secret';
    public static readonly CONCUR_SCOPE = 'api.params.scope';
    public static readonly CONCUR_EMPLOYEE_ID = 'field.employee.id';
    public static readonly CONCUR_EMPLOYEE_NAME = 'field.employee.full_name';
    public static readonly CONCUR_EMPLOYEE_EMAIL = 'field.employee.email';
    public static readonly CONCUR_EMPLOYEE_FISCAL_CODE = 'field.employee.fiscal_code';
    public static readonly CONCUR_EMPLOYEE_COMPANY = 'field.employee.company';
    public static readonly CONCUR_EMPLOYEE_GROUPS = 'field.employee.groups';
    public static readonly CONCUR_EMPLOYEE_COE = 'field.employee.coe';
    public static readonly CONCUR_EMPLOYEE_OU6 = 'field.employee.ou_06';
    public static readonly CONCUR_COMPANY_LIST_NAME = 'field.company.list_name';
    public static readonly CONCUR_COMPANY_FISCAL_CODE_MANDATORY = 'field.employee.fiscal_code.mandatory';
    public static readonly CONFIG_GEOLOCALIZATION = 'api.urls.geolocation';
    public static readonly CONFIG_API_PREFIX = 'api.urls.api_prefix';
    public static readonly CONFIG_AUTHORIZATION = 'api.urls.authorization';
    public static readonly CONFIG_ARCHIVER = 'api.params.archiver.url';
    public static readonly CONFIG_ARCHIVER_USERNAME = 'api.params.archiver.username';
    public static readonly CONFIG_ARCHIVER_CREDENTIALS = 'api.params.archiver.password';

    public static readonly AUTH_CONCUR_ERROR_CONNECTION = 'AUTH_CONCUR_ERROR_CONNECTION';
    public static readonly AUTH_CONCUR_ERROR_COMPANY = 'AUTH_CONCUR_ERROR_COMPANY';

    public static readonly RECORD_ACTIVE = 0;
    public static readonly RECORD_ARCHIVED = 1;
}
