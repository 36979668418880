import { Injectable } from '@angular/core';

import { Constants } from '@app/core/constants/constants';
import { AuthResponse } from '@app/shared/models/auth-response.model';
import { Customer } from '@app/shared/models/customer.model';
import { User } from '@app/shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageManagementService {

    constructor() { }

    //
    // ─── GENERIC METHODS ────────────────────────────────────────────────────────────
    //

    getLocalStorage(): string {
        return JSON.stringify(localStorage);
    }

    getItem(item: string): string | null {
        return localStorage.getItem(item);
    }

    setItem(item: string, value: any): void {
        localStorage.setItem(item, value);
    }

    removeItem(item: string): void {
        localStorage.removeItem(item);
    }

    clearLocalStorage(): void {
        localStorage.clear();
    }

    //
    // ─── TOKEN METHODS ──────────────────────────────────────────────────────────────
    //

    getToken(): string | null {
        return localStorage.getItem(Constants.TOKEN_NAME);
    }

    setToken(token: string): void {
        localStorage.setItem(Constants.TOKEN_NAME, token);
    }

    getRefreshToken(): string | null {
        return localStorage.getItem(Constants.REFRESH_TOKEN_NAME);
    }

    setRefreshToken(token: string): void {
        localStorage.setItem(Constants.REFRESH_TOKEN_NAME, token);
    }

    setAuthTokens(authResponse: AuthResponse): void {
        this.setToken(authResponse.token);
        this.setRefreshToken(authResponse.refresh_token);
    }

    deleteTokens(): void {
        localStorage.removeItem(Constants.TOKEN_NAME);
        localStorage.removeItem(Constants.REFRESH_TOKEN_NAME);
    }

    //
    // ─── USER DATA METHODS ──────────────────────────────────────────────────────────
    //

    setUserData(user: User): void {
        localStorage.setItem(Constants.USER_DATA, JSON.stringify(user));
    }

    getUserData(): User | null {
        return JSON.parse(localStorage.getItem(Constants.USER_DATA) as string) as User;
    }

    deleteUserData(): void {
        localStorage.removeItem(Constants.USER_DATA);
    }

    //
    // ─── CUSTOMER SELECTION METHODS ─────────────────────────────────────────────────
    //

    setSelectedCustomer(customer: Customer): void {
        localStorage.setItem(Constants.SELECTED_CUSTOMER, JSON.stringify(customer));
    }

    getSelectedCustomer(): Customer | null {
        const cachedCustomer = localStorage.getItem(Constants.SELECTED_CUSTOMER);

        return cachedCustomer && cachedCustomer !== 'undefined'
            ? JSON.parse(cachedCustomer)
            : null;
    }

    deleteSelectedCustomer(): void {
        localStorage.removeItem(Constants.SELECTED_CUSTOMER);
    }

}
