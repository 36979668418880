import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { ConstantsService } from './constants.service';

import { Constants } from '@app/core/constants/constants';
import { User } from '@app/shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    constructor(
        private authService: AuthService,
        private constantsService: ConstantsService
    ) { }

    /**
     * Checks if the given roles have authorization
     *
     * @method checkAuthorization
     * @param authorizedRoleTags roles to check authorization against
     * @param inclusivity only | except
     * @return an observable containing the authorization response
     */
    checkAuthorization(authorizedRoleTags: string | string[], inclusivity: 'only' | 'except'): Observable<boolean> {
        return new Observable((observer: Observer<any>) => {
            this.constantsService.getConstants().pipe(
                switchMap((constants: any) => this.authService.getLoggedUser().pipe(
                    map((user: User) => ({ constants, user }))
                ))
            ).subscribe((result: { constants: any; user: User }) => {
                const userRole = result.constants[Constants.USER_ROLES].find(
                    (role: any) => role.id === +result.user.role_id
                );

                if (inclusivity === 'only') {
                    observer.next(this.checkPermissionOnly(userRole.tag, authorizedRoleTags));
                } else if (inclusivity === 'except') {
                    observer.next(this.checkPermissionExcept(userRole.tag, authorizedRoleTags));
                }
            });
        });
    }

    checkPermissionOnly(userRoleTag: string, authorizedRolesTags: string | string[]): boolean {
        if (typeof authorizedRolesTags === 'string') {
            return userRoleTag === authorizedRolesTags;
        } else {
            return authorizedRolesTags.find(role => role === userRoleTag) !== undefined;
        }
    }

    checkPermissionExcept(userRoleTag: string, authorizedRolesTags: string | string[]): boolean {
        if (typeof authorizedRolesTags === 'string') {
            return userRoleTag !== authorizedRolesTags;
        } else {
            return authorizedRolesTags.find(role => role === userRoleTag) === undefined;
        }
    }

}
