import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { AuthorizationService } from '@app/core/services/base/authorization.service';

@Directive({
    selector: '[rcAuthorizeOnly]'
})
export class AuthorizeOnlyDirective implements OnInit, OnDestroy {
    private authorizationSubscription!: Subscription;

    @Input('rcAuthorizeOnly') authorizeExcept!: string | string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authorizationService: AuthorizationService
    ) { }

    ngOnInit(): void {
        this.applyAuthorization();
    }

    applyAuthorization(): void {
        this.authorizationSubscription = this.authorizationService
            .checkAuthorization(this.authorizeExcept, 'only').subscribe(
                (authorized: boolean) => {
                    if (authorized) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    } else {
                        this.viewContainer.clear();
                    }
                },
                () => this.viewContainer.clear()
            );
    }

    ngOnDestroy(): void {
        this.authorizationSubscription.unsubscribe();
    }

}
