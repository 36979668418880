import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Country } from '../models/country.model';

@Directive({
    selector: '[rcValidCountry]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ValidCountryDirective, multi: true }]
})
export class ValidCountryDirective {

    constructor() { }

    @Input() countries!: Country[];

    validate(control: AbstractControl): ValidationErrors | null {
        return this.countries ? countryValidator(this.countries)(control) : null;
    }

}

export const countryValidator = (countries: Country[]): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
        const selectedCountry = countries.find((country: Country) => country.id === control.value);

        return (!control.value || selectedCountry) ? null : { invalidCountry: { value: control.value } };
    };

