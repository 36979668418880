import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { UploadFile, UploadInput, UploaderOptions, UploadOutput } from 'ngx-uploader';

import * as crc32 from 'crc-32';

@Component({
    selector: 'rc-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
    public uploaderOptions!: UploaderOptions;
    public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

    protected form!: UntypedFormGroup;
    protected formControlName!: string;

    constructor() { }

    //
    // ─── FILES - METHODS ────────────────────────────────────────────────────────────
    //

    onUploadOutput(output: UploadOutput, form: UntypedFormGroup, formControlName: string): void {
        this.formControlName = formControlName;
        this.form = form;

        if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            this.calculateHash(output.file);
        } else if (output.type === 'rejected') {
            form.controls[formControlName].setValue(null);
            form.controls[formControlName].setErrors({ invalidContentType: true });
        }
    }

    completeUpload(file: UploadFile, hash: string): void {
        const myReader: FileReader = new FileReader();
        myReader.onloadend = () => {
            if (myReader.result) {
                const tmpB64String = myReader.result.toString().split(',')[1];
                const fileName = file.name || '';

                this.form.controls[this.formControlName].patchValue({
                    filename: fileName,
                    hash: hash,
                    filestream: tmpB64String
                });

                this.form.controls[this.formControlName].setErrors(null);
            }
        };
        myReader.readAsDataURL(file.nativeFile as Blob);
    }

    removeFile(form: UntypedFormGroup, formControlName: string, formControlNameLabel: string): void {
        this.uploadInput.emit({ type: 'remove' });
        form.controls[formControlName].patchValue(null);
        form.controls[formControlNameLabel].patchValue(null);
    }

    //
    // ─── UTILITY METHODS ────────────────────────────────────────────────────────────
    //

    setupNgxUploadOptions(): void {
        this.uploaderOptions = {
            concurrency: 1,
            maxUploads: 1
        };
    }

    calculateHash(file: UploadFile): void {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const fileResult = new Uint8Array(fileReader.result as ArrayBuffer);
            // eslint-disable-next-line no-bitwise
            const hash = this.lpad((crc32.buf(fileResult) >>> 0).toString(16), 8, '0').toUpperCase();
            this.completeUpload(file, hash);
        };
        fileReader.readAsArrayBuffer(file.nativeFile as Blob);
    }

    lpad(s: string, len: number, chr: string): string {
        const L = len - s.length;
        const C = chr || ' ';
        if (L <= 0) {
            return s;
        }
        return new Array(L + 1).join(C) + s;
    }

}
