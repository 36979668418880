import { Component } from '@angular/core';

@Component({
    selector: 'rc-layout-auth',
    template: `
        <router-outlet></router-outlet>
        <rc-footer></rc-footer>
    `,
    styles: []
})
export class LayoutAuthComponent { }
