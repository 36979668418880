import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rc-data-protection-dialog',
    templateUrl: './data-protection-dialog.component.html',
    styleUrls: ['./data-protection-dialog.component.scss']
})
export class DataProtectionDialogComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
