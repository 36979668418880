<header class="rc-header">
    <div class="rc-header__linksWrap">
        <a class="rc-header__logo"
            routerLink="/">
            <picture class="rc-image">
                <source media="(min-width: 0px)"
                    [srcset]="logoSrc">
                <img class="rc-image__image"
                    [src]="logoSrc"
                    alt="Company Logo">
            </picture>
        </a>

        <span class="rc-header__selectedCustomer"
            *ngIf="loggedUser.customers.length > 1">
            {{selectedCustomer.name}}
        </span>
    </div>

    <div class="rc-header__linksWrap">
        <span class="rc-header__link"
            routerLink="/dashboard"
            routerLinkActive="-active">Dashboard</span>
        <span class="rc-header__link"
            routerLink="/conserva"
            routerLinkActive="-active"
            *rcAuthorizeExcept="constants.ADMIN">ConservApp</span>
        <span class="rc-header__link"
            routerLink="/revoice"
            routerLinkActive="-active"
            *ngIf="!environment.qa && !environment.production">Revoice</span>

        <div class="rc-header__menuSelect" routerLinkActive="-active">
            <span *ngIf="loggedUser" class="rc-header__link">
                {{loggedUser.name}} {{loggedUser.surname}}
            </span>

            <button mat-icon-button
                type="button"
                [matMenuTriggerFor]="menu">
                <mat-icon class="rc-header__menuSelectIcon">keyboard_arrow_down</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item
                    routerLink="/settings">
                    <span class="rc-header__menuSelectBtn"> {{'COMMON.SETTINGS' | translate}}</span>
                </button>

                <button mat-menu-item
                    (click)="onLogout()">
                    <span class="rc-header__menuSelectBtn"> {{'COMMON.LOGOUT' | translate}}</span>
                </button>
            </mat-menu>
        </div>
    </div>
</header>
