import { Pipe, PipeTransform } from '@angular/core';

import { formatRelative } from 'date-fns';
import { it, enUS } from 'date-fns/locale';

import { AvailableLanguages } from '../types/types';

@Pipe({
    name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {

    transform(value: string | undefined, language: AvailableLanguages): string {
        if (value) {
            const locale = language === 'it'
                ? it
                : enUS;
            return formatRelative(new Date(value), new Date(), { locale });
        } else {
            return '';
        }
    }

}
