<h1 mat-dialog-title>{{data.dialogTitle}}</h1>

<div mat-dialog-content>
    <p>{{data.dialogBody}}</p>
</div>

<div align="end" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">
        {{'COMMON.CANCEL' | translate}}
    </button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
        {{'COMMON.CONFIRM' | translate}}
    </button>
</div>
