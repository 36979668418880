<div class="rc-settingsPage__colorPickerWrap"
    [ngClass]="{ '-primary': type === 'primary', '-primaryLight': type === 'accent' }">
    <div class="rc-settingsPage__colorPickerInfoWrap">
        <span class="rc-settingsPage__colorCategory">{{label}}</span>
        <span class="rc-settingsPage__colorCode">{{hexColor}}</span>
    </div>
    <div class="rc-settingsPage__colorPickerCircle" [style.background]="color">
        <input class="rc-settingsPage__colorPickerCircleInput " [(colorPicker)]="color" [style.background]="color"
            [cpOKButton]="true" cpOKButtonClass="rc-colorPickerConfirmBtn"
            (colorPickerSelect)="onColorPickerSelected($event)" cpOutputFormat="hsla" [cpDisabled]="isDisabled">
    </div>
</div>
