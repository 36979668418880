import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { CustomersService } from '@app/core/services/customers.service';
import { LocalStorageManagementService } from '@app/core/services/base/local-storage-management.service';
import { UtilsService } from '@app/core/services/base/utils.service';

import { environment } from '@env/environment';

import { Constants } from '@app/core/constants/constants';
import { Customer, CustomerSetting } from '@app/shared/models/customer.model';
import { User } from '@app/shared/models/user.model';

@Component({
    selector: 'rc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    public constants = Constants;
    public loggedUser!: User;
    public logoSrc = 'assets/images/logos/logo-alteaup.png';
    public environment = environment;
    private userSettingsByTag!: Record<string, any>;
    private customerSettingsSubscription!: Subscription;

    constructor(
        private authService: AuthService,
        private constantsService: ConstantsService,
        private customersService: CustomersService,
        private localStorageService: LocalStorageManagementService,
        private utilsService: UtilsService
    ) {}

    get selectedCustomer(): Customer {
        const customer =
            this.localStorageService.getSelectedCustomer() ??
            this.loggedUser.customers[0];

        return customer;
    }

    ngOnInit(): void {
        this.getConstants();
        this.getLoggedUser();
        this.listenForCustomerSettingsUpdate();
    }

    getConstants(): void {
        this.constantsService
            .getConstants()
            .subscribe((constants: Record<string, any>) => {
                this.userSettingsByTag = this.utilsService.arrayToObject(
                    constants[Constants.CUSTOMER_SETTING_TYPES],
                    'tag'
                );
            });
    }

    getLoggedUser(): void {
        this.authService.getLoggedUser().subscribe((user: User) => {
            const customer: Customer =
                this.localStorageService.getSelectedCustomer() ??
                user.customers[0];
            this.loggedUser = user;

            if (customer && customer.settings) {
                this.updateAppLogo(customer.settings);
            }
        });
    }

    listenForCustomerSettingsUpdate(): void {
        this.customerSettingsSubscription =
            this.customersService.customerSettingsUpdated$.subscribe(
                (settings: CustomerSetting[]) => this.updateAppLogo(settings)
            );
    }

    updateAppLogo(settings: CustomerSetting[]): void {
        const logoSetting = settings.find(
            (setting: CustomerSetting) =>
                setting.type_id === this.userSettingsByTag[Constants.LOGO].id
        );

        if (logoSetting) {
            this.logoSrc = `${environment.apiUrl}/${logoSetting.value}`;
        }
    }

    onSelectCustomer(customerId: number): void {
        const selectedCustomer = this.loggedUser.customers.find(
            (customer: Customer) => customer.id === customerId
        );

        if (selectedCustomer) {
            this.localStorageService.setSelectedCustomer(selectedCustomer);
        }
    }

    onLogout(): void {
        this.authService.logout().subscribe();
    }

    ngOnDestroy(): void {
        this.customerSettingsSubscription.unsubscribe();
    }
}
