import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Customer, CustomerSetting } from '@app/shared/models/customer.model';

@Injectable({
    providedIn: 'root'
})
export class CustomersService {
    private customerSettingsUpdatedSource = new Subject<CustomerSetting[]>();
    public customerSettingsUpdated$ = this.customerSettingsUpdatedSource.asObservable();

    constructor(
        private httpClient: HttpClient
    ) { }

    getCustomers(): Observable<Customer[]> {
        const url = 'customers';

        return this.httpClient.get<Customer[]>(url);
    }

    createCustomer(customerData: Record<string, unknown>): Observable<Customer> {
        const url = 'customers';

        return this.httpClient.post<Customer>(url, customerData);
    }

    editCustomer(customerId: number, customerData: Record<string, unknown>): Observable<Customer> {
        const url = `customers/${customerId}`;

        return this.httpClient.patch<Customer>(url, customerData);
    }

    enableCustomer(customerId: number): Observable<void> {
        const url = `customers/${customerId}/enable`;

        return this.httpClient.patch<void>(url, null);
    }

    disableCustomer(customerId: number): Observable<void> {
        const url = `customers/${customerId}/disable`;

        return this.httpClient.patch<void>(url, null);
    }

    updateCustomerSettings(customerId: number, data: Record<string, unknown>): Observable<Customer> {
        const url = `customers/${customerId}/settings`;

        return this.httpClient.patch<Customer>(url, data).pipe(
            tap((customer: Customer) => this.notifyCustomerSettingsUpdate(customer.settings))
        );
    }

    updateCustomerConfigurations(customerId: number, data: Record<string, unknown>): Observable<void> {
        const url = `customers/${customerId}/configurations`;

        return this.httpClient.patch<void>(url, data);
    }

    //
    // ─── RXJS ───────────────────────────────────────────────────────────────────────
    //

    notifyCustomerSettingsUpdate(settings: CustomerSetting[]): void {
        this.customerSettingsUpdatedSource.next(settings);
    }

}
