import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'backendTranslation'
})
export class BackendTranslationPipe implements PipeTransform {

    transform(value: string): string {
        return `BKND.${value}`;
    }

}
