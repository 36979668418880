<h1 mat-dialog-title>{{'COMMON.DATA_PROTECTION' | translate}}</h1>

<div mat-dialog-content>
    <h2>{{'PRVC.001' | translate}}</h2>
    <p>{{'PRVC.002' | translate}}</p>
    <p>{{'PRVC.003' | translate}}</p>
    <p>{{'PRVC.004' | translate}}</p>

    <h3>{{'PRVC.005' | translate}}</h3>
    <p>{{'PRVC.006' | translate}}</p>
    <p>{{'PRVC.007' | translate}}</p>
    <p>{{'PRVC.008' | translate}}</p>
    <p>{{'PRVC.009' | translate}}</p>
    <p>{{'PRVC.010' | translate}}</p>

    <h3>{{'PRVC.011' | translate}}</h3>
    <p>{{'PRVC.012' | translate}}</p>
    <p>{{'PRVC.013' | translate}}</p>

    <h3>{{'PRVC.014' | translate}}</h3>
    <p>{{'PRVC.015' | translate}}</p>
    <p>{{'PRVC.016' | translate}}</p>
    <p>{{'PRVC.017' | translate}}</p>

    <h3>{{'PRVC.018' | translate}}</h3>
    <p>{{'PRVC.019' | translate}}</p>
    <p>{{'PRVC.020' | translate}}</p>
    <p>{{'PRVC.021' | translate}}</p>
    <p>{{'PRVC.022' | translate}}</p>
    <p>{{'PRVC.023' | translate}}</p>

    <h3>{{'PRVC.024' | translate}}</h3>
    <p>{{'PRVC.025' | translate}}</p>
    <p>{{'PRVC.026' | translate}}</p>

    <h3>{{'PRVC.027' | translate}}</h3>
    <p>{{'PRVC.028' | translate}}</p>

    <h3>{{'PRVC.029' | translate}}</h3>
    <p>{{'PRVC.030' | translate}}</p>
</div>

<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>{{'COMMON.CLOSE' | translate}}</button>
</div>
