<footer class="rc-footer">
    <div class="rc-footer__logos">
        <img
            src="/assets/images/altea-logos.png"
            alt="Altea and Altea UP logos"
            width="152"
        />
    </div>

    <div class="rc-footer__info">
        <span class="rc-footer__infoLastLogin">
            <ng-container *ngIf="loggedUser">
                {{ "COMMON.LAST_LOGIN" | translate }}:
                {{ loggedUser.last_login_at | date: "dd/MM/YYYY h:mm a" }}
            </ng-container>
        </span>
        <div class="rc-footer__infoLinks">
            <span (click)="openDataProtectionDialog()">
                {{ "COMMON.DATA_PROTECTION" | translate }}
            </span>
            <p>{{ "COMMON.SAP_INTEGRATED" | translate }}</p>
        </div>
        <span class="rc-footer__infoCopyright">
            Copyright 2020 - Altea UP - Tutti i diritti riservati
        </span>
    </div>
</footer>
